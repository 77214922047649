<template>
  <div>
    <div class="p-3 bg-gray-1 h-100">
      <div v-if="paymentSelected.imageUrl">
        <img
          :src="paymentSelected.imageUrl"
          :alt="paymentSelected.imageUrl"
          class="payment-image"
        />

        <!-- <font-awesome-icon
          v-if="isCanEdit"
          icon="times-circle"
          class="text-secondary delete-icon pointer"
          @click="$emit('onDeleteImage')"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    paymentSelected: {
      required: true,
      type: Object
    },
    orderStatus: {
      required: true,
      type: Number
    },
    isCanEdit: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      permission: "getPermission"
    })
  }
};
</script>

<style scoped>
.payment-image {
  width: 100%;
  height: 100%;
}
.bg-gray-1 {
  background-color: #e7e6e6;
  min-height: 500px;
}
.delete-icon {
  position: absolute;
  right: 23px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
}
</style>
