var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"textcustom"},[_vm._v("รายการชำระเงิน")]),_c('div',[_vm._v("ยอดชำระเงินรวม ฿ "+_vm._s(_vm._f("numeral")(_vm.total,"0,0.00")))])]),_vm._l((_vm.list),function(payment,pindex){return _c('b-col',{key:((payment.id) + "-" + pindex),staticClass:"px-0"},[_c('div',{staticClass:"d-flex mt-2 w-100"},[_c('div',{class:[
          'd-flex payment-box',
          _vm.paymentSelectedIndex == pindex
            ? 'payment-box-active'
            : 'payment-box-unactive'
        ],on:{"click":function($event){return _vm.$emit('onPaymentSelected', payment, pindex)}}},[_c('div',{staticClass:"w-100 px-2 pb-2 pt-1 f-size-14"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(payment.transferDate,_vm.$formatDate))+" ("+_vm._s(_vm._f("moment")(payment.transferTime,_vm.$formatTime))+") ")]),(payment.bankAccountId)?_c('div',{staticClass:"d-flex"},[_c('div',[_c('img',{staticClass:"bank-image",attrs:{"src":_vm.getBankAccount(payment.bankAccountId).imageUrl,"alt":_vm.getBankAccount(payment.bankAccountId).bankName}})]),_c('div',{staticClass:"ml-2 text-gray-2"},[_c('div',[_vm._v(" "+_vm._s(_vm.getBankAccount(payment.bankAccountId).accountName)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getBankAccount(payment.bankAccountId).accountNo)+" ")])])]):_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"bank-image"}),_c('div',{staticClass:"ml-2 text-danger"},[_vm._v(" กรุณาระบุธนาคารปลายทาง ")])])]),_c('div',{class:[
            'd-flex align-items-center justify-content-center',
            _vm.paymentSelectedIndex == pindex ? 'bg-purple-1' : 'bg-gray-1'
          ]},[_c('div',[_vm._v(" "+_vm._s(_vm._f("numeral")(payment.amount,"0,0.00"))+" ")])])]),(_vm.isCanEdit && payment.delete != false)?_c('b-button',{staticClass:"text-danger p-0 pl-1 cursor-default",attrs:{"variant":"link","type":"button"}},[_c('font-awesome-icon',{staticClass:"pointer",attrs:{"icon":"trash","title":"Delete"},on:{"click":function($event){return _vm.$emit('onDeleteSlip', payment, pindex)}}})],1):_vm._e()],1),(_vm.v.$each.$iter[pindex].$error)?_c('span',{staticClass:"text-error f-size-14"},[_vm._v("กรุณากรอกข้อมูลให้ครบถ้วน")]):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }