<template>
  <div>
    <b-modal
      v-model="modalShow"
      title="รายละเอียดการชำระเงิน"
      hide-footer
      size="xl"
      header-class="header-payment"
    >
      <div>
        <div>
          <b-row>
            <b-col class="payment-list pr-1" cols="12" lg="4">
              <PaymentList
                ref="paymentList"
                :bankAccountList="bankAccountList"
                :paymentDetailList="paymentDetailList"
                :paymentSelected="paymentSelected"
                :v="$v.formApprovePayment"
                v-on:onPaymentSelected="onPaymentSelected"
                :paymentSelectedIndex="paymentSelectedIndex"
                v-on:onDeleteSlip="onDeleteSlip"
                :isCanEdit="canEdit"
              />
              <div class="text-center" v-if="canEdit">
                <b-button class="btn-main-4 mt-3" @click="addSlip"
                  >เพิ่มรายการ</b-button
                >
              </div>
            </b-col>
            <b-col class="payment-image mt-4 px-1" cols="12" lg="4">
              <PaymentImage
                :paymentSelected="paymentSelected"
                :orderStatus="orderStatus"
                v-on:onDeleteImage="onDeleteImage"
                :isCanEdit="canEdit"
              />
            </b-col>
            <b-col class="payment-detail mt-4 pl-1" cols="12" lg="4">
              <PaymentDetailForm
                :bankAccountList="bankAccountList"
                :paymentSelected="paymentSelected"
                :invoiceNo="invoiceNo"
                :orderTotalAmount="orderTotalAmount"
                :orderStatus="orderStatus"
                :v="$v.formApprovePayment.$each.$iter[paymentSelectedIndex]"
                @onDataChange="handlePaymentDetailForm"
                :isCanEdit="canEdit"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="d-flex justify-content-between mt-4" v-if="orderStatus == 3">
        <div class="ml-2">
          <b-button
            class="w-100 btn-main-4"
            @click="onCheckformReject"
            :disabled="isDisable"
            >ปฏิเสธ</b-button
          >
        </div>
        <div class="ml-2">
          <b-button
            class="w-100 btn-main"
            @click="onCheckformApprove"
            :disabled="isDisable"
            >ยืนยัน</b-button
          >
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-content-end">
          <b-button class="btn-main" @click="hide">ตกลง</b-button>
        </div>
      </div>
    </b-modal>
    <ModalRejectPayment
      ref="modalRejectPayment"
      v-on:submitRejectPayment="onRejectPayment"
    />
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hide="hadleModalAlertClose"
    />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalTextError
      :textModal="textModal"
      :statusModal="statusModal"
      @onApprovePayment="onApprovePayment"
    />
  </div>
</template>

<script>
// import InputText from "@/components/inputs/InputText";
import { required, minValue } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalTextError from "./ModalTextError.vue";
import PaymentList from "../payment/PaymentList.vue";
import PaymentImage from "../payment/PaymentImage.vue";
import PaymentDetailForm from "../payment/PaymentDetailForm.vue";
import ModalRejectPayment from "../modal/ModalRejectPayment.vue";
import { mapGetters } from "vuex";
import * as moment from "moment/moment";

export default {
  components: {
    PaymentList,
    PaymentImage,
    PaymentDetailForm,
    ModalRejectPayment,
    // InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    ModalTextError
  },
  props: {
    orderId: {
      required: true,
      type: [Number, String]
    },
    transactionId: {
      required: true,
      type: Number
    },
    invoiceNo: {
      required: true,
      type: String
    },
    orderTotalAmount: {
      required: true,
      type: Number
    },
    orderStatus: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      modalShow: false,
      paymentSelectedIndex: 0,
      paymentDetailList: [],
      formApprovePayment: [],
      formRejectPayment: {
        ReasonId: 0,
        Note: ""
      },
      bankAccountList: [],
      modalMessage: "",
      isDisable: false,
      paymentSelected: {},
      isLoadedForWatch: false,
      canEdit: true,

      // state modal
      textModal: "",
      statusModal: true
    };
  },
  validations: {
    formApprovePayment: {
      $each: {
        bankAccountId: {
          required,
          minValue: minValue(1)
        },
        amount: {
          required
        },
        paymentDate: {
          required
        },
        note: {},
        imageUrl: { required }
      }
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      permission: "getPermission"
    })
  },
  watch: {
    paymentSelected: {
      handler() {
        if (this.isLoadedForWatch) this.handlePaymentDetailForm();
      },
      deep: true
    }
  },
  created() {
    this.canEdit = !(![2, 3].includes(this.orderStatus) || !this.permission);
  },
  methods: {
    onAddNewPayment() {
      let paymentDetail = {
        id: this.paymentDetailList[this.paymentDetailList.length - 1].id - 1,
        bankAccountId: this.bankAccountList[0].id,
        amount: 0.0,
        transferDate: moment().format(),
        transferTime: moment().format(),
        imageUrl: "",
        transactionId: this.transactionId,
        deleteImage: null,
        uploadImage: null,
        isSelected: true
      };
      this.paymentDetailList.push(paymentDetail);
      this.formApprovePayment.push({
        id: this.formApprovePayment[this.formApprovePayment.length - 1],
        bankAccountId: this.bankAccountList[0].id,
        amount: 0,
        paymentDate: moment().format(),
        note: "",
        imageUrl: ""
      });
      this.paymentSelected = this.paymentDetailList[
        this.paymentDetailList.length - 1
      ];
    },
    onDeleteImage() {
      this.paymentSelected.imageUrl = "";
      this.paymentSelected.imageUrlName = "";
    },
    onPaymentSelected(payment, paymentSelectedIndex) {
      this.paymentSelected = payment;
      this.paymentSelectedIndex = paymentSelectedIndex;
      this.paymentSelected.note = "";
    },
    getPaymentDetail: async function() {
      this.isLoadedForWatch = false;
      this.paymentDetailList = [];
      await this.$axios
        .get(
          `${this.$baseUrl}/api/transaction/${this.transactionId}/transferList`
        )
        .then(async data => {
          let now = this.$moment().format(`YYYY-MM-DDTHH:mm:ss`);
          if (data.data.detail.length > 0) {
            let paymentDetailList = data.data.detail;
            await paymentDetailList.forEach(async element => {
              element.delete = false;
              if (element.transferDate.search("0001-01-01T") >= 0) {
                element.transferDate = now;
              }
              element.transferTime = element.transferTime.split(".")[0];
              await this.paymentDetailList.push({
                ...element,
                imageUrlName: element.imageUrl,
                note: ""
              });
              let paymentDate = `${element.transferDate.substring(0, 10)}T${
                element.transferTime
              }`;

              this.addFormApprovePayment(element, paymentDate);
            });
            this.onPaymentSelected(this.paymentDetailList[0], 0);
            await this.getBankList();
          } else {
            let paymentDetail = this.initDataPayment(now);
            this.paymentDetailList.push(paymentDetail);
            this.formApprovePayment.push({
              id: 0,
              bankAccountId: 0,
              amount: 0,
              paymentDate: now,
              note: "",
              imageUrl: ""
            });
            this.paymentSelected = this.paymentDetailList[0];
            await this.getBankList();
          }
        });
      this.isLoadedForWatch = true;
    },
    getBankList: async function() {
      await this.$axios
        .get(
          `${this.$baseUrl}/api/transaction/bankaccount?transactionId=${this.transactionId}`
        )
        .then(data => {
          this.bankAccountList = data.data.detail;
        });
    },
    onCheckformReject() {
      this.$refs.modalRejectPayment.show();
    },
    onCheckformApprove() {
      this.$v.formApprovePayment.$touch();
      if (this.$v.formApprovePayment.$error) {
        return;
      }

      let priceDefault = this.orderTotalAmount;

      let priceInput = 0;
      this.paymentDetailList.forEach(x => {
        priceInput += parseFloat(x.amount);
      });

      // reset status modal
      this.statusModal = true;

      if (priceInput < priceDefault) {
        this.textModal = "ยอดโอนที่ระบุน้อยกว่าราคาสุทธิ";
        this.statusModal = false;
        this.$bvModal.show("modal-text-error");
      } else if (priceInput == priceDefault) {
        this.textModal = `ยอดโอนเท่ากับราคาสุทธิ`;
        this.$bvModal.show("modal-text-error");
      } else if (priceInput > priceDefault) {
        this.textModal = `ยอดโอนที่ระบุมากกว่าราคาสุทธิ`;
        this.$bvModal.show("modal-text-error");
      }
    },
    onApprovePayment: async function() {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      await this.$axios
        .put(`${this.$baseUrl}/api/transaction/${this.orderId}/approve`, {
          paymentList: this.formApprovePayment
        })
        .then(data => {
          this.isDisable = false;
          if (data.data.result == 1) {
            this.modalMessage = "ยืนยันการชำระเงินสำเร็จ";
            this.$refs.modalLoading.hide();
            this.$refs.modalAlert.show();
          } else {
            this.modalMessage = data.data.message;
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
      this.$emit("handleForcrrefresh");
    },
    onRejectPayment: async function(form) {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      await this.$axios
        .put(`${this.$baseUrl}/api/transaction/${this.orderId}/reject`, form)
        .then(data => {
          this.isDisable = false;
          if (data.data.result == 1) {
            this.modalMessage = "ปฏิเสธการชำระเงินสำเร็จ";
            this.$refs.modalRejectPayment.hide();
            this.$refs.modalLoading.hide();
            this.$refs.modalAlert.show();
          } else {
            this.modalMessage = data.data.message;
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
      this.$emit("handleForcrrefresh");
    },
    hadleModalAlertClose() {
      this.hide();
      this.$emit("onRefreshData");
    },
    show: async function() {
      this.canEdit = !(![2, 3].includes(this.orderStatus) || !this.permission);
      this.formApprovePayment = [];
      this.$v.formApprovePayment.$reset();
      await this.getPaymentDetail();
      this.modalShow = true;
    },
    hide() {
      this.modalShow = false;
    },
    addFormApprovePayment(data, paymentDate) {
      this.formApprovePayment.push({
        id: data.id,
        bankAccountId: data.bankAccountId,
        amount: data.amount,
        paymentDate: paymentDate,
        imageUrl: data.imageUrl,
        note: ""
      });
    },
    initDataPayment(datetime) {
      let data = {
        id: 0,
        bankAccountId: 0,
        amount: 0,
        transferDate: datetime,
        transferTime: datetime,
        imageUrl: "",
        transactionId: 0,
        deleteImage: null,
        uploadImage: null,
        note: "",
        imageUrlName: ""
      };
      return data;
    },
    handlePaymentDetailForm: async function() {
      this.paymentDetailList[this.paymentSelectedIndex] = await this
        .paymentSelected;
      await this.$refs.paymentList.updateList(this.paymentDetailList);
      let paymentDetail = this.paymentDetailList[this.paymentSelectedIndex];
      if (paymentDetail.transferTime.length > 8) {
        paymentDetail.transferTime = paymentDetail.transferTime.substring(
          11,
          19
        );
      }
      let paymentDate = `${paymentDetail.transferDate.substring(0, 10)}T${
        paymentDetail.transferTime
      }`;
      this.formApprovePayment[this.paymentSelectedIndex].amount =
        paymentDetail.amount;
      this.formApprovePayment[this.paymentSelectedIndex].bankAccountId =
        paymentDetail.bankAccountId;
      this.formApprovePayment[
        this.paymentSelectedIndex
      ].paymentDate = paymentDate;
      this.formApprovePayment[this.paymentSelectedIndex].imageUrl =
        paymentDetail.imageUrl;
      this.formApprovePayment[this.paymentSelectedIndex].note =
        paymentDetail.note;
    },
    addSlip: async function() {
      let now = this.$moment().format(`YYYY-MM-DDTHH:mm:ss`);
      let newPayment = await this.initDataPayment(now);
      this.paymentDetailList.push(newPayment);
      let paymentDate = `${newPayment.transferDate.substring(
        0,
        10
      )}T${newPayment.transferTime.substring(11, 19)}`;
      this.addFormApprovePayment(newPayment, paymentDate);
    },
    onDeleteSlip: async function(payment, index) {
      if (this.paymentDetailList.length > 1) {
        if (this.paymentSelectedIndex == index) {
          this.paymentSelectedIndex = 0;
        } else if (this.paymentSelectedIndex > index) {
          this.paymentSelectedIndex -= 1;
        }
        if (index == 0) {
          this.initFirstPayment();
        }
        this.formApprovePayment.splice(index, 1);
        this.paymentDetailList.splice(index, 1);
        this.paymentSelected = await this.paymentDetailList[
          this.paymentSelectedIndex
        ];
      } else {
        this.initFirstPayment();
      }
    },
    initFirstPayment: async function() {
      let now = this.$moment().format(`YYYY-MM-DDTHH:mm:ss`);
      this.paymentSelected = await this.initDataPayment(now);
      this.paymentDetailList[0] = this.paymentSelected;
      this.paymentSelectedIndex = 0;
      let paymentDate = `${this.paymentSelected.transferDate.substring(
        0,
        10
      )}T${this.paymentSelected.transferTime.substring(11, 19)}`;
      this.formApprovePayment[this.paymentSelectedIndex] = {
        id: this.paymentSelected.id,
        bankAccountId: this.paymentSelected.bankAccountId,
        amount: this.paymentSelected.amount,
        paymentDate: paymentDate,
        note: "",
        imageUrl: this.paymentSelected.imageUrl
      };
    }
  }
};
</script>

<style lang="scss" scoped>
// del /deep/
.header-payment {
  background: #eae2f3;
}
.payment-list,
.payment-image,
.payment-detail {
  height: auto;
  overflow: hidden;
  overflow-y: auto;
}
</style>
