<template>
  <div>
    <b-modal
      id="modal-text-error"
      title="แจ้งเตือนจากระบบ"
      size="sm"
      hide-footer
      body-class="p-0"
    >
      <div>
        <div class="p-3">
          <p class="my-2" :class="!statusModal && 'text-red'">
            {{ textModal }}
          </p>
        </div>
        <div
          class="modal-new-footer p-3"
          :class="statusModal && 'd-flex justify-content-between'"
        >
          <b-button class="btn-cancel" @click="closeModal">
            กลับไปแก้ไข
          </b-button>
          <b-button class="btn-confirm" @click="confirm" v-if="statusModal">
            ดำเนินการต่อ
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    textModal: {
      type: String,
      default: ""
    },
    statusModal: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-text-error");
    },
    confirm() {
      this.$emit("onApprovePayment");
      this.$bvModal.hide("modal-text-error");
    }
  }
};
</script>

<style scoped>
.btn-cancel {
  min-width: none;
  background-color: #ffffff;
  color: #7160c4;
  border-color: #7160c4;
}
.btn-confirm {
  background-color: #7160c4;
  border-color: #7160c4;
  color: white;
}
.text-red {
  color: red;
}
.modal-new-footer {
  border-top: 1px solid #d8dbe0;
  padding: 16px;
}
</style>
